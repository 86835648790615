.search {
  position: fixed;
  top: 50px;
  z-index: 10;
  background-color: rgb(241, 241, 241);
}

.listItem {
  padding: 5px;
}

.listItem:hover {
  padding: 5px;
  background-color: rgb(219, 224, 224);
  border-radius: 5px;
}

/* Mobile screen  */
@media only screen and (max-width: 600px) {
  .searchPosition {
    border-radius: 5px;
    margin-top: 45px;
    width: 420px;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Desktop screen  */
@media only screen and (min-width: 1024px) {
  .searchPosition {
    border-radius: 5px;
    width: 480px;
  }
}
