/* Mobile screen  */

@media only screen and (max-width: 600px) {
  .TwoImgHead {
    height: 200px;
  }
  .heightOfImg {
    height: 200px;
  }
  .widthOfImg {
    width: 150px;
  }

  .heightOfBigImg {
    height: 100%;
  }
  .widthOfBigImg {
    width: 100%;
  }

  .flexImg {
    display: flex;
    flex-wrap: nowrap;
  }
  .flex-container-side{
    margin-left: 25px;
  }
}

/* Desktop screen  */

@media only screen and (min-width: 600px) {
  .TwoImgHead {
    height: 500px;
  }
  .heightOfImg {
    height: 400px;
  }
  .widthOfImg {
    width: 330px;
  }

  .heightOfBigImg {
    height: 100%;
  }
  .widthOfBigImg {
    width: 100%;
  }

  .flex-container-side{
    margin-left: 50px;
  }

}

/* image  */
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .item {
    flex: 1 0 50%;
  }
}

@media (min-width: 600px) {
  .item {
    flex: 1 0 33.33%;
  }
}
