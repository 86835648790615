/* Mobile screen  */

@media only screen and (max-width: 600px) {
  .navBarMobile {
    display: none;
  }
}

/* Desktop screen  */

@media only screen and (min-width: 600px) {
  .navBarDesktop {
    display: none;
  }
}
