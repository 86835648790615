@import "~react-image-gallery/styles/css/image-gallery.css";

.link {
  text-decoration: none;
  color: black;
}
.link:hover {
  color: rgb(19, 71, 214);
}

.navBarIndex {
  position: fixed;
  top: 0px;
  z-index: 7;
}

/* Mobile screen  */
@media only screen and (max-width: 600px) {
  .carousel {
    position: relative;
    z-index: 1;
    margin-top: 90px !important;
  }

  .desktopMargin {
    margin-top: 100px;
  }
}

.carousel {
  position: relative;
  z-index: 1;
}

.fontsize40 {
  font-size: 40px;
}

.fontsize20 {
  font-size: 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: rgb(248, 248, 248);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

/* hide scrollbar but allow scrolling */
removeScrollBar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

removeScrollBar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
/*  card hover in homepage */
.imgHoverEffect:hover {
  transform: scale(1.03);
  transition: 0.3s;
}
